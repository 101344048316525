import React, { useState } from "react";

import { useSelector } from "react-redux";

import { Layout, Row, Col } from "antd";

import HeaderHorizontal from "./components/header/HeaderHorizontal";
import MenuFooter from "./components/footer";
import CustomiseTheme from "./components/customise";
import ScrollTop from "./components/scroll-to-top";
import Sidebar from "./components/menu/Sidebar";
import TradingHeader from "./components/trading_header";
import Background from "../view/pages/authentication/background";

const { Content } = Layout;

export default function HorizontalLayout(props) {
    const { children } = props;
    const [visible, setVisible] = useState(false);

    // Redux
    const customise = useSelector(state => state.customise)

    return (
        <Layout className="hp-app-layout">
        <Background />
      <Layout className="hp-bg-black-20 hp-bg-color-dark-90">
          <TradingHeader setVisible={setVisible} />

          <Content className="hp-content-main">
              <Row justify="center">
                  {
                      customise.contentWidth === "full" && (
                          <Col xxl={24} xl={24} span={24}>
                              {children}
                          </Col>
                      )
                  }

                  {
                      customise.contentWidth === "boxed" && (
                          <Col className="hp-w-100" style={{ maxWidth: 936 }}>
                              {children}
                          </Col>
                      )
                  }
              </Row>
          </Content>

          <MenuFooter />
      </Layout>

 

      <ScrollTop />
  </Layout>
    );
};