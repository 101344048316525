import { Health, Document ,Setting, Grid5, Chart } from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";
import Dashboard from "../../assets/icons/dashboard.svg"

const main = [
   
    {
        id: "dashboards-analytics",
        title: "Panel",
        icon: <img src={Dashboard} height="40" alt="Bot Icon" />, // Aquí se utiliza el icono SVG importado como una imagen
        navLink: "/",
    },
    //{
    //    id: "dashboards-ecommerce",
    //    title: <IntlMessages id="sidebar-dashboards-ecommerce" />,
    //    icon: <Setting size={18} />,
    //    navLink: "/main/dashboard/ecommerce",
    //},
    //{
    //    id: "dashboards-nft",
    //    title: <IntlMessages id="sidebar-dashboards-nft" />,
    //    icon: <Grid5 size={18} />,
    //    navLink: "/main/dashboard/nft",
    //},
];

export default main