import { Button, Card, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AxiosInstance from "../../../axiosInstance";
import Cookies from "js-cookie";
import image from "../../../assets/images/memoji/newspaper.svg";
import { RiMoneyDollarCircleFill, RiMoneyDollarCircleLine } from "react-icons/ri";
import HeaderLanguages from "./HeaderLanguages";

export default function HeaderText() {

  return (
    <>
   
    </>
  
  );
};