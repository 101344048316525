import React from "react";
import { Link } from "react-router-dom";

import { Divider, Avatar, Row, Col, Badge } from "antd";
import { RiFacebookBoxLine, RiInstagramLine, RiSettings3Line, RiTelegramLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

import IntlMessages from "../../lang/IntlMessages";
import avatar from "../../../../assets/images/memoji/user-avatar-4.png";

export default function MenuFooter(props) {

  const userData = useSelector((state) => state.userData.userData);


  return (
    props.collapsed === false ? (
      <Row
        className="hp-sidebar-footer hp-bg-color-dark-90"
        align="middle"
        justify="space-between"
      >

        <Col style={{paddingBottom: 10}} span={24}>
          <Row>
            <Col>
           
            <Avatar
              size={50}
              src={userData? userData.avatar : ""}
              className="hp-bg-info-4 hp-mr-8"
              style={{ borderRadius: '80%' }} // Aplicar un borde redondeado al 50%
            />
          
      
            </Col>
            <Col style={{paddingLeft: 10}} span={12}>
              <Row style={{fontSize: 14, color: '#FFF'}}>
                <Col span={24}>{userData ? userData.username : ""}</Col>
                <Col span={24}>{userData ? userData.email : ""}</Col>
              </Row>
            </Col>
          </Row>
        </Col>


        <Divider className="hp-border-color-black-40 hp-border-color-dark-70 hp-mt-0" />

      
        <Col style={{marginTop: 10}} span={24}>
          <p style={{
              opacity: 0.1,
              fontSize: 12,
              fontWeight: 200,
              lineHeight: 1.5,
              letterSpacing: '0.05em', // Ajusta el valor según tu preferencia
          }}>
            Lavid es una marca registrada de nuestra empresa. Todos los derechos reservados. El uso de este sitio web y de sus servicios está sujeto a los siguientes <a href="#">términos y condiciones</a>.</p>
          </Col>
      </Row>
    ) : (
      <Row
        className="hp-sidebar-footer hp-bg-color-dark-90"
        align="middle"
        justify="center"
      >
        <Col>
          <Link
            to="/pages/profile/personel-information"
            onClick={props.onClose}
          >
            <Avatar size={48} src={avatar} className="hp-bg-info-4" />
          </Link>
        </Col>
      </Row>
    )
  );
};