import React, {useState, useEffect} from "react";

import { useSelector } from "react-redux";

import { Button, Badge, Row, Col, Dropdown, Divider, Avatar } from "antd";
import { NotificationBing, TickCircle } from 'iconsax-react';
import Cookies from "js-cookie";
import avatarImg1 from "../../../assets/images/memoji/user-avatar-1.png";
import avatarImg2 from "../../../assets/images/memoji/user-avatar-2.png";
import avatarImg3 from "../../../assets/images/memoji/user-avatar-3.png";
import AxiosInstance from "../../../axiosInstance";
import moment from "moment";

// Define un objeto de mapeo para los periodos de tiempo
const timeAgoMap = [
  { unit: "año", value: 365 },
  { unit: "mes", value: 30 },
  { unit: "día", value: 1 }
];

// Función para calcular la diferencia de tiempo en "Hace ..."
const getTimeAgo = (timestamp) => {
  const currentDate = moment();
  const notificationDate = moment(timestamp);
  const diffDays = currentDate.diff(notificationDate, 'days');
  if (diffDays >= 365) {
    const diffYears = Math.floor(diffDays / 365);
    return `Hace ${diffYears} ${diffYears === 1 ? "año" : "años"}`;
  } else if (diffDays >= 30) {
    const diffMonths = Math.floor(diffDays / 30);
    return `Hace ${diffMonths} ${diffMonths === 1 ? "mes" : "meses"}`;
  } else if (diffDays >= 1) {
    return `Hace ${diffDays} ${diffDays === 1 ? "día" : "días"}`;
  } else {
    return "Recién";
  }
};


export default function HeaderNotifications() {
  const token = Cookies.get("token");
  const direction = useSelector(state => state.customise.direction)
  const [notifications, setNotifications] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(0);

  const fetchRetiros = async () => {
    const headers = {
      'Authorization': token // Adjunta el token en el encabezado de autorización
    };
    try {
      const response = await AxiosInstance.post("/api/notifications", {}, { headers });
      setNotifications(response.data);
      setTotalNotifications(response.data.length); // Actualiza el estado de totalNotifications con la longitud de la respuesta

    } catch (error) {
      console.error("Error al obtener los retiros", error);
    }
  };
  
  useEffect(() => {
    fetchRetiros(); // Cuando el componente se monta, obtiene los datos de las wallets
  }, []);

  const notificationMenu = (
    <div className="hp-notification-dropdown hp-border-radius hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-pt-24 hp-pb-18 hp-px-24" style={{ marginTop: 23 }}>
      <Row wrap={false} align="middle" justify="space-between" className="hp-mb-16">
        <Col className="h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mr-64">
          Notificaciones
        </Col>

        <Col className="hp-badge-text hp-font-weight-500 hp-text-color-black-80 hp-ml-24">
          ({totalNotifications})
        </Col>
      </Row>

      <Divider className="hp-mt-0 hp-mb-4" />

      <div className="hp-overflow-y-auto hp-px-10" style={{ maxHeight: 400, marginRight: -10, marginLeft: -10 }}>
     
      {notifications.map(notification => (
          <Row key={notification.id} className="hp-cursor-pointer hp-border-radius hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-py-12 hp-px-10" style={{ marginLeft: -10, marginRight: -10 }}>
            <Col className="hp-mr-12">
              <Avatar
                size={48}
                icon={<TickCircle size={24} variant="Bold" className="hp-text-color-success-1" />}
                className="hp-d-flex-center-full hp-bg-success-4 hp-bg-dark-success"
              />
            </Col>

            <Col flex="1 0 0">
              <p className="hp-d-block hp-font-weight-500 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 hp-mb-4">
                <span className="hp-text-color-black-60">{notification.content}</span>
              </p>

              <span className="hp-d-block hp-badge-text hp-font-weight-500 hp-text-color-black-60 hp-text-color-dark-40">
              {getTimeAgo(notification.timestamp)}
              </span>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );

  return (
    <Col className="hp-d-flex-center">
      <Button
        ghost
        type="primary"
        className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
        icon={
          <Dropdown overlay={notificationMenu} placement="bottomRight">
            <div className="hp-position-relative">
              <div className="hp-position-absolute" style={direction == "rtl" ? { left: -5, top: -5 } : { right: -5, top: -5 }}>
                <Badge
                  dot
                  status="processing"
                />
              </div>

              <NotificationBing size="22" className="hp-text-color-black-80 hp-text-color-dark-30" />
            </div>
          </Dropdown>
        }
      />
    </Col>
  );
};