const initialState = {
  saldoUser: 10, // Inicialmente, no hay datos de usuario
};

const userSaldoReducer = (state = initialState, action) => {
  switch (action.type) {
      case "ACTUALIZAR_SALDO":
        return {
          ...state,
          saldoUser: action.payload,
      };
    default:
      return state;
  }
};

export default userSaldoReducer;