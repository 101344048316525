import React, { useState, useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import { Layout, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import MenuFooter from "./components/footer";
import CustomiseTheme from "./components/customise";
import ScrollTop from "./components/scroll-to-top";
import Background from "../view/pages/authentication/background";
import Cookies from "js-cookie";
const { Content } = Layout;

export default function VerticalLayout(props) {
    const history = useHistory();

    const dispatch = useDispatch();

    const { children } = props;

    const [visible, setVisible] = useState(false);

    // Redux
    const customise = useSelector(state => state.customise)

    useEffect(() => {
        const token = Cookies.get("token");
        if (!token) {
          history.push("/login");
        }
      }, []);

    return (
        <Layout className="hp-app-layout">
              <Background />
            <Sidebar visible={visible} setVisible={setVisible} />

            <Layout className="hp-bg-black-20 hp-bg-color-dark-90">
                <MenuHeader setVisible={setVisible} />

                <Content className="hp-content-main">
                    <Row justify="center">
                        {
                            customise.contentWidth === "full" && (
                                <Col xxl={24} xl={24} span={24}>
                                    {children}
                                </Col>
                            )
                        }

                        {
                            customise.contentWidth === "boxed" && (
                                <Col className="hp-w-100" style={{ maxWidth: 936 }}>
                                    {children}
                                </Col>
                            )
                        }
                    </Row>
                </Content>

                <MenuFooter />
            </Layout>

       

            <ScrollTop />
        </Layout>
    );
};