import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Form, Input, Button, message } from "antd";
import Background from "../background";
import Header from "../header";
import Footer from "../footer";
import axiosInstance from "../../../../axiosInstance";
import { SHA256 } from "crypto-js";
import { useParams } from "react-router-dom";

export default function SignUp() {
  const history = useHistory();
  const { referrer } = useParams();
  const [inputActiveReferrer, setInputActiveReferrer] = useState(false);
  const [validationCode, setValidationCode] = useState("");
  const [isCodeSending, setIsCodeSending] = useState(false);
  const [isCodeCooldown, setIsCodeCooldown] = useState(false);
  const [cooldownTimeLeft, setCooldownTimeLeft] = useState(0);

  useEffect(() => {
    if (referrer === "") {
      setInputActiveReferrer(true);
    } else {
      setInputActiveReferrer(false);
    }
  }, [referrer]);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    referrer: referrer || "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    code: "",
  });

  const handleFormSubmit = async () => {
    try {
      const hashedPassword = SHA256(formData.password).toString();
      const hashedconfirmPassword = SHA256(formData.confirmPassword).toString();
      const updatedFormData = { ...formData, hash: validationCode };
      
      const response = await axiosInstance.post("/api/signup", updatedFormData);
  
      if (response.status === 200) {
        message.success(response.data.message);
        history.push("/login");
      } else if (response.status === 400) {
        message.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.message);
      } else {
        message.error("Error al registrar.");
      }
    }
  };

  const sendVerificationCode = async () => {
    try {
      if (isCodeCooldown || isCodeSending) {
        message.warning("Espere 2 minutos antes de enviar otro código de verificación.");
        return;
      } else if (!validateEmail(formData.email)) {
        message.warning("Por favor, ingrese un correo electrónico válido.");
        return;
      }
      
      setIsCodeSending(true);
      const response = await axiosInstance.post("/api/verify_email", { email: formData.email });
      if (response.status === 200) {
        message.success("Código de verificación enviado al correo electrónico.");
        setValidationCode(response.data.hash);
        setIsCodeCooldown(true);
        let cooldownTimer = 120; // segundos
        setCooldownTimeLeft(cooldownTimer);
        const timer = setInterval(() => {
          if (cooldownTimer === 0) {
            clearInterval(timer);
            setIsCodeCooldown(false);
            setIsCodeSending(false);
          } else {
            setCooldownTimeLeft(cooldownTimer);
            cooldownTimer--;
          }
        }, 1000);
      } else {
        message.error("Error al enviar el código de verificación.");
      }
    } catch (error) {
      message.error("Error al enviar el código de verificación.");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Row className="hp-authentication-page hp-d-flex">
      <Background />
      <Col span={24}>
        <Header />
      </Col>
      <Col flex="1 0 0">
        <Row justify="center" align="middle" className="hp-m-auto">
          <Col xs={17} sm={15} md={12} lg={8} xl={8}>
            <Form layout="vertical" name="basic" className="hp-mt-sm-16 hp-mt-32">
              <Form.Item label="Usuario">
                <Input
                  value={formData.username}
                  onChange={(e) => setFormData({ ...formData, username: e.target.value.toUpperCase() })}
                />
              </Form.Item>

              <Form.Item label="Correo" rules={[{ validator: validateEmail }]}>
                <Row gutter={8}>
                  <Col span={18}>
                    <Form.Item
                      name="Correo"
                      noStyle
                      rules={[{ required: true, message: 'Por favor, ingrese su correo electrónico' }]}
                    >
                       <Input
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />
                    </Form.Item>
                    <p style={{paddingTop: 10, fontSize: 11, opacity: .5}}>{validationCode}</p>
                  </Col>
                  <Col span={6}>
                    <Button style={{width: '100%'}} onClick={sendVerificationCode} disabled={isCodeSending || isCodeCooldown}>
                      {isCodeCooldown ? `(${cooldownTimeLeft} seg)` : "Enviar código"}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item label="Codigo">
                <Input value={formData.code} onChange={(e) => setFormData({ ...formData, code: e.target.value.toUpperCase() })} />
              </Form.Item>

              <Form.Item label="Referido">
                <Input
                  value={formData.referrer}
                  onChange={(e) => setFormData({ ...formData, referrer: e.target.value.toUpperCase() })}
                  disabled={inputActiveReferrer}
                />
              </Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item label="Primer nombre">
                    <Input
                      value={formData.firstName}
                      onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Apellido">
                    <Input
                      value={formData.lastName}
                      onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Contraseña">
                <Input.Password
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                />
              </Form.Item>
              <Form.Item label="Confirma contraseña">
                <Input.Password
                  value={formData.confirmPassword}
                  onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                />
              </Form.Item>
            </Form>
            <Button block type="primary" onClick={handleFormSubmit}>
              Registrarme
            </Button>
            <div className="hp-form-info hp-text-center hp-mt-8">
              <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                Ya tienes una cuenta?
              </span>
              <Link
                to="/login"
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
              >
                Iniciar sesión
              </Link>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
