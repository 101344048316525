import React, { useRef, useState, useEffect, createContext, useContext } from "react";
import { Layout, Button, Row, Col, Modal, Input, Checkbox, Steps, message, Divider  } from "antd";
import { RiCloseLine, RiExchangeDollarLine, RiMenuFill, RiMoneyDollarBoxLine, RiWallet3Line } from "react-icons/ri";
import { SearchNormal1 } from 'iconsax-react';
import { motion } from 'framer-motion';
import Cookies from "js-cookie";
import HeaderSearch from './HeaderSearch';
import HeaderUser from "./HeaderUser";
import HeaderNotifications from "./HeaderNotifications";
import HeaderLanguages from "./HeaderLanguages";
import HeaderCart from "./HeaderCart";
import HeaderText from "./HeaderText";
import HeaderThemeDark from "./HeaderThemeDark";
import { ReactComponent as UsdtIcon } from  "../../../assets/images/crypto/usdt.svg";
import AxiosInstance from "../../../axiosInstance";
const { Header } = Layout;
import { useDispatch, useSelector } from 'react-redux';
import { setUserSaldo } from '../../../redux/users/saldoActions';
import usdt from '../../../assets/images/crypto/usdt.svg'
import { red } from "@mui/material/colors";
import { SvgIcon } from "@mui/material";

const DataContext = createContext();

export default function MenuHeader(props) {
  
  const dispatch = useDispatch();
  const saldo = useSelector(state => state.saldoUsuario.saldoUser);

  const userData = useSelector((state) => state.userData.userData);
  const userRole = userData ? userData.role : 0;

  const { setVisible } = props;
  const token = Cookies.get("token");
  const [searchHeader, setSearchHeader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [data, setData] = useState([]); // Estado local para almacenar los datos
  const [totalGanancias, setTotalGanancias] = useState(0); // Estado local para almacenar los datos 
  const [depositarModal, setDepositarModal] = useState(false);
  const [withdrawModal, setWithdrawalModal] = useState(false);
  const [withdrawMonto, setwithdrawMonto] = useState();
  const [topUpWallet, setTopUpWalletModal] = useState(false);
  const [balance, setBalance] = useState(0); // Estado para almacenar el saldo actual de la billetera
  const [monto, setMonto] = useState('');
  const [token2fa, setToken] = useState('');
  const [terminosChecked, setTerminosChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purchaseData, setPurchaseData] = useState(null); // Estado local para almacenar los datos
  const [withdrawData, setWithdrawData] = useState(null); // Estado local para almacenar los datos

  const [currentStep, setStep] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState('');
  
  const { Step } = Steps;

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return 'Pendiente';
      case 2:
        return 'Completado';
      case 3:
        return 'Rechazado';
      default:
        return 'Desconocido';
    }
  };

  const handleRecargar = async () => {
    setLoading(true);
    try {
      const token = Cookies.get("token"); // Obtiene el token de las cookies
      const response = await AxiosInstance.post("/api/recharge_account", {
        rechargeData: monto
      }, {
        headers: {
          Authorization: token // Agrega el token al encabezado
        }
      });
  
      if (response.status === 200 && response.data.success) {
        // Realiza las acciones necesarias en caso de éxito
        setPurchaseData(response.data.datos);
        message.success("Orden creada exitosamente");
        setStep(1);
    
      } else {
        // Maneja el caso en el que la compra falla
        message.error("Error en la compra");
      }
    } catch (error) {
      // Maneja errores generales aquí
      console.error("Error en la solicitud:", error);
      message.error("Error en la solicitud");
    }
  };

  const handleRetirar = async () => {
    setLoading(true);
    try {
      const token = Cookies.get("token"); // Obtiene el token de las cookies
      const response = await AxiosInstance.post("/api/withdraw", {
        amount: withdrawMonto, code: token2fa
      }, {
        headers: {
          Authorization: token // Agrega el token al encabezado
        }
      });
  
      if (response.status === 200 && response.data.success) {
        setLoading(false);
        // Realiza las acciones necesarias en caso de éxito
        //setRetiroData(response.data.datos);
        dispatch(setUserSaldo(saldo - withdrawMonto));
        message.success(response.data.message);
        setWithdrawData(response.data.datos);
        setStep(3)
    
      } else {
        setLoading(false);
        // Maneja el caso en el que la compra falla
        message.error(response.data.message);
      }
    } catch (error) {
      // Maneja errores generales aquí
      console.error("Error en la solicitud:", error);
      message.error("Error en la solicitud");
    }
  };


  const handleClose = () => {
    // Lógica para recargar la billetera
    // Puedes abrir otro modal o realizar alguna acción específica para recargar fondos
    setDepositarModal(false);
  };

  const handleContinuar = () => {
    // Lógica para retirar fondos de la billetera
    // Puedes abrir otro modal o realizar alguna acción específica para retirar fondos
    setStep(2);
  };

  // Focus
  const inputFocusRef = useRef(null);
  const inputFocusProp = {
    ref: inputFocusRef,
  };

  // Search Active
  setTimeout(() => setSearchActive(searchHeader), 100);

  const searchClick = () => {
    setSearchHeader(true)

    setTimeout(() => {
      inputFocusRef.current.focus({
        cursor: 'start',
      });
    }, 200);
  }

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
    setSearchHeader(false);
  };

  const handleModalTopUp = () => {
    setTopUpWalletModal(true);
  }


  const handleDepositarModal = () => {
    setTopUpWalletModal(false);
    setDepositarModal(true);
  }

  const handleRetirarModal = () => {
    setTopUpWalletModal(false);
    setWithdrawalModal(true);
  }

  // Children
  const headerChildren = () => {
      
  useEffect(() => {
    const headers = {
        'Authorization': token // Adjunta el token en el encabezado de autorización
    };

    AxiosInstance.post('/api/datos', {} , { headers })
      .then((response) => {
        setData(response.data);
        setTotalGanancias(response.data.totalGanancias);
        dispatch(setUserSaldo(response.data.totalGanancias.toFixed(2)));
      })
      .catch((error) => {
        console.error('Error al obtener datos:', error);
      });
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const expirationDate = new Date(purchaseData?.expiration_estimate_date);
      const timeDiff = expirationDate.getTime() - now.getTime();

      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeRemaining('Expirado');
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [purchaseData]);


  
    return (
      <Row
        className="hp-w-100 hp-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col className="hp-mobile-sidebar-button hp-mr-24">
          <Button
            type="none"
            ghost
            className="hp-mobile-sidebar-button hp-border-none"
            onClick={showDrawer}
            icon={
              <RiMenuFill
                size={24}
                className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
              />
            }
          />
        </Col>

        <Col
          flex="1"
          style={{ display: !searchHeader ? 'none' : 'block' }}
          className={`hp-mr-md-0 hp-mr-16 hp-pr-0 hp-header-search ${searchActive && "hp-header-search-active"}`}
        >
          <HeaderSearch inputFocusProp={inputFocusProp} setSearchHeader={setSearchHeader} />
        </Col>

        {!searchHeader && (
          <HeaderText />
        )}



          <Col>
            <Row align="middle">
              
            {userRole !== 1 ? (
              <Col style={{ borderRadius: 20, paddingLeft: 20, marginRight: 10 }} className=" hp-d-flex-center">
                <UsdtIcon style={{ width: '1em', height: '1em' }} />
                <p style={{ marginRight: 10, color: "#FFF" }} className="hp-header-left-text-item hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 hp-ml-12 hp-mb-0">
                  <a style={{ color: "#fff" }}>
                    ${saldo}
                  </a>
                </p>

                <Button onClick={handleModalTopUp} style={{ borderRadius: 20 }}><RiWallet3Line /></Button>

                {/* Contenido adicional si lo necesitas */}
                
              </Col>

         
            ) : null}
              
              
              <HeaderNotifications />
              <HeaderUser />
            </Row>
          </Col>



      </Row>
    )
  }
  



  return (
    <Header>
    <Modal
          title="Mi wallet"
          visible={topUpWallet}
          footer={null}
          centered
          width={1000}
          onCancel={() => setTopUpWalletModal(false)}
        >
          <p className="title" style={{textAlign: 'center', fontSize: '28px', marginBottom: '0px' }}>
           ${saldo} USDT
          </p>
          <p  style={{letterSpacing: 1, textAlign: 'center', marginBottom: '20px' }}>
           Billetera (USDT)
          </p>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <Button type="primary" style={{letterSpacing: 1 ,marginRight: '10px' }} onClick={handleDepositarModal}>
              Depositar
            </Button>
            <Button type="primary"  style={{letterSpacing: 1 ,marginRight: '10px' }} onClick={handleRetirarModal}>
              Retirar
            </Button>
          </div>
       
        </Modal>

        <Modal
          title="Depositar"
          visible={depositarModal}
          onCancel={() => setDepositarModal(false)}
          footer={null}
          centered
          width="60%"
        >

        <div style={{paddingBottom: '30px'}} className="hp-overflow-scroll hp-scrollbar-x-hidden">
            <Steps size="small" current={currentStep}>
              <Step title="" />
              <Step title="" />
              <Step title="" />
            </Steps>
          </div>

      
          {currentStep === 0 && (
            <div>
            <div style={{ marginBottom: '20px' }}>
            <Input
              prefix="$"
              suffix="USDT"
              placeholder="Ingrese el monto a recargar"
              value={monto}
              onChange={(e) => setMonto(e.target.value)}
              style={{ marginBottom: '10px' }}
            />
            <Input
              prefix="RED"
              placeholder="TRC20"
              disabled
              style={{ marginBottom: '10px' }}
            />
            <Checkbox
              checked={terminosChecked}
              onChange={(e) => setTerminosChecked(e.target.checked)}
              style={{ marginBottom: '10px', paddingTop: '10px' }}
            >
              Estoy de acuerdo con los términos y condiciones
            </Checkbox>
            
   
          </div>


          <Button
            type="primary"
            onClick={handleRecargar}
            disabled={!terminosChecked || !monto}
            loading={loading}
          >
            Recargar
          </Button>
          </div>
          )}

          {currentStep === 1 && ( // Renderiza los datos de compra si están disponibles
            <div style={{ marginTop: '20px'}}>
              <Row>
                <Col span={12}><p>Transaccion ID</p></Col>
                <Col span={12}><p>#{purchaseData.payment_id}</p></Col>

                <Col span={12}><p>Expiracion</p></Col>
                <Col span={12}><p>{timeRemaining}</p></Col>


                <Col span={12} style={{textAlign: 'left'}}><p>Cantidad a pagar</p></Col>
                <Col span={12}><h3>$ {purchaseData.pay_amount.toFixed(2)}</h3></Col>
              
                <Col span={12}><p>Billetera</p></Col>
                <Col span={12}><p>{purchaseData.pay_address}</p></Col>

                <Col align="right" style={{paddingTop: 30}} span={24}>

                <Button
                 
                  type="primary"
                  onClick={handleContinuar}
                >
                 Continuar
                </Button>

                </Col>
                <Divider></Divider>
                <Col span={24}><p style={{opacity: 0.5, letterSpacing: 0.5, fontSize: 12}}>Por favor, ten en cuenta que es crucial enviar la cantidad correcta a la dirección especificada. Si la cantidad enviada no coincide con el monto requerido o se envía a una dirección incorrecta, tu transacción puede no procesarse correctamente y tu paquete podría no ser activado. Asegúrate de verificar cuidadosamente la cantidad y la dirección antes de realizar la transacción para garantizar una recarga exitosa de tu billetera. ¡Gracias por tu atención a este importante detalle!</p></Col>
              {/* Aquí puedes mostrar otros datos de compra según tus necesidades */}
              </Row>
            </div>
            
          )}

          {currentStep === 2 && ( // Renderiza los datos de compra si están disponibles
            <div style={{ marginTop: '20px', textAlign: 'center'}}>
              <Row>
                <Col span={24}><h3>Tu saldo sera acreditado al verificar el pago automaticamente</h3></Col>
            

                <Col align="right" style={{paddingTop: 30}} span={24}>
            
                <Button
         
                  type="primary"
                  onClick={handleClose}
                >
                 Entendido
                </Button>

                </Col>
                <Divider></Divider>
                <Col span={24}><p style={{opacity: 0.5, letterSpacing: 0.5, fontSize: 12}}>Por favor, ten en cuenta que es crucial enviar la cantidad correcta a la dirección especificada. Si la cantidad enviada no coincide con el monto requerido o se envía a una dirección incorrecta, tu transacción puede no procesarse correctamente y tu paquete podría no ser activado. Asegúrate de verificar cuidadosamente la cantidad y la dirección antes de realizar la transacción para garantizar una recarga exitosa de tu billetera. ¡Gracias por tu atención a este importante detalle!</p></Col>
              {/* Aquí puedes mostrar otros datos de compra según tus necesidades */}
              </Row>
            </div>
            
          )}
  

      
        </Modal>

        <Modal
          title="Retirar"
          visible={withdrawModal}
          onCancel={() => setWithdrawalModal(false)}
          footer={null}
          centered
          width="50%"
        >

        <div style={{paddingBottom: '30px'}} className="hp-overflow-scroll hp-scrollbar-x-hidden">
            <Steps size="small" current={currentStep}>
              <Step title="" />
              <Step title="" />
              <Step title="" />
            </Steps>
          </div>

      
          {currentStep === 0 && (
            <div>
            <div style={{ marginBottom: '20px' }}>

            <Row gutter={[16,16]}>
              <Col>
                <img src={usdt} width={50} alt="" />
              </Col>
              <Col>
                <h5 style={{opacity: .5, marginBottom: 0}}>Tether</h5>
                <p style={{fontSize: 20,  paddingTop: 0}}>{saldo}</p>
              </Col>
            </Row>

            <Input
              prefix="$"
              suffix="USDT"
              placeholder="Ingrese el monto a retirar"
              value={withdrawMonto}
              onChange={(e) => setwithdrawMonto(e.target.value)}
              style={{ marginBottom: '10px' }}
            />
            
    

            <Input
              placeholder="Token 2FA"
              value={token2fa}
              onChange={(e) => setToken(e.target.value)}
              style={{ marginBottom: '10px' }}
            />

            <Checkbox
              checked={terminosChecked}
              onChange={(e) => setTerminosChecked(e.target.checked)}
              style={{ marginBottom: '10px', paddingTop: '10px' }}
            >
              Estoy de acuerdo con los términos y condiciones
            </Checkbox>
            
   
          </div>


          <Button
            type="primary"
            onClick={handleRetirar}
            disabled={!terminosChecked || !withdrawMonto}
            loading={loading}
          >
            Retirar
          </Button>
          </div>
          )}

          {currentStep === 3 && ( // Renderiza los datos de compra si están disponibles
            <div style={{ marginTop: '20px'}}>
              <Row>
                <Col span={12}><p>Transaccion ID</p></Col>
                <Col span={12}><p>#{withdrawData.transactionId}</p></Col>

                <Col span={12} style={{textAlign: 'left'}}><p>Cantidad a recibir</p></Col>
                <Col span={12}><h3>$ {withdrawData.mount}</h3></Col>
              
                <Col span={12}><p>Billetera</p></Col>
                <Col span={12}><p>{withdrawData.pay_address}</p></Col>

                <Col span={12}><p>Estado</p></Col>
                <Col span={12}><p>{getStatusText(withdrawData.status)}</p></Col>

                <Col align="right" style={{paddingTop: 30}} span={24}>

        

                </Col>
               
              {/* Aquí puedes mostrar otros datos de compra según tus necesidades */}
              </Row>
            </div>
            
          )}

          {currentStep === 4 && ( // Renderiza los datos de compra si están disponibles
            <div style={{ marginTop: '20px', textAlign: 'center'}}>
              <Row>
                <Col span={24}><h3>Tu saldo sera acreditado al verificar el pago automaticamente</h3></Col>
            

                <Col align="right" style={{paddingTop: 30}} span={24}>
            
                <Button
         
                  type="primary"
                  onClick={handleClose}
                >
                 Entendido
                </Button>

                </Col>
              
              {/* Aquí puedes mostrar otros datos de compra según tus necesidades */}
              </Row>
            </div>
            
          )}
  

  <Divider></Divider>
  <Col span={24}>
    <p style={{ opacity: 0.5, letterSpacing: 0.5, fontSize: 12 }}>
      Ten en cuenta que los retiros son procesados automáticamente. Una vez solicitado el retiro de fondos en TRC20 USDT, es posible que este proceso de confirmación pueda tardar hasta 24 horas en completarse. Este tiempo puede variar dependiendo de la congestión en la red TRC20 USDT y otros factores relacionados con la blockchain. Sin embargo, el tiempo promedio de confirmación suele situarse en torno a los 10 minutos.
      Te agradecemos tu paciencia y comprensión durante este proceso. Si tienes alguna pregunta o inquietud adicional, no dudes en contactar con nuestro equipo de soporte.
    </p>
  </Col>

    </Modal>


      <Row justify="center" className="hp-w-100">
        <Col span={24}>
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 0.5, delay: 0.1 }}
            className="hp-w-100"
          >
            {headerChildren()}
          </motion.div>
        </Col>
      </Row>
    </Header>
  );
};