import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Select, Card, Typography, Divider, Avatar, List, Progress, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import RevenueColumnCard from "../../widgets/cards/analytics/revenueColumnCard";
import ProjectTableEcommerceCard from "./projectTableEcommerceCard";
import EarningsDonutCard from "./earningsDonutCard";
import CtaCardTwo from "../../widgets/cards/advance/ctaCardTwo";
import RevenueLineEcommerceList from "./revenueLineEcommerceList";
import CheckDetailCard from "./checkDetailCard";
import ECommerceProductCard from "./eCommerceProductCard";
import ECommerceBrowserStates from "./eCommerceBrowserStates";
import CustomerFeatureCard from "../../widgets/cards/advance/customerFeatureCard";
import OrdersFeatureCard from "../../widgets/cards/advance/ordersFeatureCard";
import SaleFeatureCard from "../../widgets/cards/advance/saleFeatureCard";
import ProfitFeatureCard from "../../widgets/cards/advance/profitFeatureCard";
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import { Discount } from "react-iconly";
import robot from "../../../../assets/images/robot.png"
import Background from "../../../pages/authentication/background";
import { createChart, ColorType } from 'lightweight-charts';
import { ReactComponent as Wallet } from '../../../../assets/icons/wallet.svg';
import { ReactComponent as Bot } from '../../../../assets/icons/bot.svg';
import { ReactComponent as User } from '../../../../assets/icons/user.svg';
import { SvgIcon } from "@mui/material";
import line from "../../../../../src/assets/images/illustrations/growth-dark.svg";
import lineDark from "../../../../../src/assets/images/illustrations/growth-dark.svg";
import era from "../../../../assets/videos/era.mp4";
import banner from '../../../../assets/images/banner.png';
import Cookies from "js-cookie";
import AxiosInstance from "../../../../axiosInstance";
import { Link } from "react-router-dom/cjs/react-router-dom";

const { Title, Text } = Typography;

export default function ECommerce() {
  const [data, setData] = useState([]); // Estado local para almacenar los datos
  const [totalGanancias, setTotalGanancias] = useState(0); // Estado local para almacenar los datos 
  const token = Cookies.get("token");
  const theme = useSelector(state => state.customise.theme)
  const [accountSettings, setAccountSettings] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const userData = useSelector((state) => state.userData.userData);

  useEffect(() => {
    const headers = {
        'Authorization': token // Adjunta el token en el encabezado de autorización
    };

    AxiosInstance.post('/api/datos', {} , { headers })
      .then((response) => {
        setData(response.data);
        setTotalGanancias(data.totalGanancias);
      })
      .catch((error) => {
        console.error('Error al obtener datos:', error);
      });
  }, []);


  const initialData = [
    { time: '2018-12-22', value: 32.51 },
  ];

  // Crear una referencia para el contenedor del gráfico
  const chartContainerRef = React.useRef(null);

  useEffect(() => {
    if (chartContainerRef.current) {
      const chart = createChart(chartContainerRef.current, {
        layout: {
          background: { color: 'transparent' },
          textColor: '#DDD',
      },
      grid: {
          vertLines: { color: 'transparent' },
          horzLines: { color: 'transparent' },
      },
        width: 900,
        height: 300,
      });
  
      const series = chart.addLineSeries({
        color: '#3BE804',
    });
      series.setData(initialData);
  
      return () => {
        // Limpiar el gráfico cuando el componente se desmonte
        chart.remove();
      };
    }
  }, []); // Se ejecuta solo una vez al montar el componente
  

  useEffect(() => {
    const token = Cookies.get("token");
    const headers = {
      'Authorization': token // Adjunta el token en el encabezado de autorización
    };
    
    const fetchAccountSettings = async () => {
      try {
        const response = await AxiosInstance.post('/api/account_info', {}, {headers});
        setAccountSettings(response.data.accountSettings);
        setSuggestions(response.data.suggestions);

      } catch (error) {
        console.error('Error fetching account settings:', error);
      }
    };

    fetchAccountSettings();
  }, []);

  return (
    <Row gutter={[32, 0]}>


      <Col span={24} className="hp-mt-32">
        <Row gutter={[32, 32]}>
        
          <Col span={18}>
         
            <Row gutter={[32, 32]}>
          
              <Col xl={12} md={12} span={24}>
              <Card className="hp-border-color-black-40">
                <Background></Background>
                <Row>
                  <Col style={{backgroundColor: "#2A0970"}} className="hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16">
                  <Wallet width="25" height="25"/>

                  </Col>

                  <Col className="hp-mt-8">
                    <h3 className="hp-mb-4">
                      ${data.totalGanancias}
              
                    </h3>

                    <p className="hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                      MI BALANCE
                    </p>
                  </Col>
                </Row>
              </Card>
              </Col>

              <Col xl={12} md={12} span={24}>
              <Card className="hp-border-color-black-40">
                <Background></Background>
                <Row>
                  <Col style={{backgroundColor: "#2A0970"}} className="hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16">
                  <Bot width="25" height="25"/>
                  </Col>

                  <Col className="hp-mt-8">
                    <h3 className="hp-mb-4">
                    ${parseFloat(0).toFixed(2)}
                 
                    </h3>

                    <p className="hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                     BONO ROI
                    </p>
                  </Col>
                </Row>
              </Card>
              </Col>

              <Col xl={24} md={12} span={24}>
              <Card  className="hp-border-color-black-40">
                <Background></Background>
                <Row>
                  <Col style={{backgroundColor: "#2A0970"}} className="hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16">
                  <User width="25" height="25"/>
                  </Col>

                  <Col className="hp-mt-8">
                    <h3 className="hp-mb-4">
                    ${parseFloat(0).toFixed(2)}
                  
                    </h3>

                    <p className="hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                     BONO DIRECTO
                    </p>
                  </Col>
                </Row>
              </Card>
              </Col>

              <Col xl={12} md={12} span={24}>
              <Card className="hp-border-color-black-40">
                <Background></Background>
                <Row>
                  <Col style={{backgroundColor: "#2A0970"}} className="hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16">
                  <Wallet width="25" height="25"/>
                  </Col>

                  <Col className="hp-mt-8">
                    <h3 className="hp-mb-4">
                    ${data.totalBots}
                      
                    </h3>

                    <p className=" hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                     VOLUMEN INVERTIDO
                    </p>
                  </Col>
                </Row>
              </Card>
              </Col>

              <Col xl={12} md={12} span={24}>
              <Card className="hp-border-color-black-40">
                <Background></Background>
                <Row>
                  <Col style={{backgroundColor: "#2A0970"}} className="hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16">
                  <Wallet width="25" height="25"/>
                  </Col>

                  <Col className="hp-mt-8">
                    <h3 className="hp-mb-4">
                    ${parseFloat(0).toFixed(2)}
                     
                    </h3>

                    <p className="hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                     VOLUMEN DE RED
                    </p>
                  </Col>
                </Row>
              </Card>
              </Col>
      


            </Row>
          </Col>

          <Col span={6} justify="center">


             
          <Card className="hp-border-color-black-40 hp-card-6 hp-border-radius-xxl hp-overflow-hidden">
            <Title level={4} className="title">Mi cuenta</Title>
            <Divider style={{ borderColor: 'blue' }} />
            {accountSettings.map((item, index) => (
              <Row key={index} align="middle" style={{ marginBottom: 16 }}>
                <Col span={24}>
                  <Text strong>{item.title}</Text>
                </Col>
               
                <Col span={24}>
                  <Progress
                    percent={(item.progress / item.total) * 100}
                    format={percent => `${item.progress}/${item.total}`}
                    status={item.progress === item.total ? 'success' : 'active'}
                    size="small"
                  />
                </Col>
              </Row>
            ))}

          {suggestions.length > 0 && (
                  <>
                    <Divider style={{ borderColor: 'purple' }} />
                    <Title level={4} className="title">Sugerencias</Title>
                    <List
                      dataSource={suggestions}
                      renderItem={(item, index) => (
                        <List.Item key={index}>
                          <Text>{item}</Text>
                        </List.Item>
                      )}
                    />
                  </>
                )}

          </Card>
       
  
          </Col>
          

 
   
        </Row>
      </Col>
    </Row>
  );
}
