import React, { useRef, useState, useEffect, createContext, useContext } from "react";
import { Layout, Button, Row, Col, Modal, Input, Checkbox, Steps, message, Divider  } from "antd";
import { RiCloseLine, RiExchangeDollarLine, RiMenuFill, RiMoneyDollarBoxLine, RiWallet3Line } from "react-icons/ri";
import { SearchNormal1 } from 'iconsax-react';
import { motion } from 'framer-motion';
import Cookies from "js-cookie";
import HeaderSearch from './HeaderSearch';
import HeaderUser from "./HeaderUser";
import HeaderNotifications from "./HeaderNotifications";
import HeaderLanguages from "./HeaderLanguages";
import HeaderCart from "./HeaderCart";
import HeaderText from "./HeaderText";
import HeaderThemeDark from "./HeaderThemeDark";
import { ReactComponent as UsdtIcon } from  "../../../assets/images/crypto/usdt.svg";
import AxiosInstance from "../../../axiosInstance";
const { Header } = Layout;
import { useDispatch, useSelector } from 'react-redux';
import { setUserSaldo } from '../../../redux/users/saldoActions';
import usdt from '../../../assets/images/crypto/usdt.svg'
import { red } from "@mui/material/colors";

const DataContext = createContext();

export default function TradingHeader(props) {
  
  const dispatch = useDispatch();
  const saldo = useSelector(state => state.saldoUsuario.saldoUser);

  const userData = useSelector((state) => state.userData.userData);
  const userRole = userData ? userData.role : 0;

  const { setVisible } = props;
  const token = Cookies.get("token");
  const [searchHeader, setSearchHeader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [data, setData] = useState([]); // Estado local para almacenar los datos
  const [totalGanancias, setTotalGanancias] = useState(0); // Estado local para almacenar los datos 
  const [depositarModal, setDepositarModal] = useState(false);
  const [withdrawModal, setWithdrawalModal] = useState(false);
  const [withdrawMonto, setwithdrawMonto] = useState();
  const [topUpWallet, setTopUpWalletModal] = useState(false);
  const [balance, setBalance] = useState(0); // Estado para almacenar el saldo actual de la billetera
  const [monto, setMonto] = useState('');
  const [token2fa, setToken] = useState('');
  const [terminosChecked, setTerminosChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purchaseData, setPurchaseData] = useState(null); // Estado local para almacenar los datos
  const [currentStep, setStep] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState('');
  
  const { Step } = Steps;

  // Focus
  const inputFocusRef = useRef(null);
  const inputFocusProp = {
    ref: inputFocusRef,
  };

  // Search Active
  setTimeout(() => setSearchActive(searchHeader), 100);



  // Children
  const headerChildren = () => {


    return (
      <Row
        className="hp-w-100 hp-position-relative"
        align="middle"
        justify="space-between"
      >
         <HeaderLanguages />

        <Col
          flex="1"
          style={{ display: !searchHeader ? 'none' : 'block' }}
          className={`hp-mr-md-0 hp-mr-16 hp-pr-0 hp-header-search ${searchActive && "hp-header-search-active"}`}
        >
          <HeaderSearch inputFocusProp={inputFocusProp} setSearchHeader={setSearchHeader} />
        </Col>

        {!searchHeader && (
          <HeaderText />
          
        )}
       




          <Col>
            <Row align="middle">
              
              <Col style={{ borderRadius: 20, paddingLeft: 20, marginRight: 10 }} className="hp-d-flex-center">

                <Button type="primary" style={{ borderRadius: 20 }}>Volver al panel</Button>

              </Col>


              <HeaderThemeDark />
              <HeaderUser />
            </Row>
          </Col>



      </Row>
    )
  }
  



  return (
    <Header>
      <Row justify="center" className="hp-w-100">
        <Col span={24}>
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 0.5, delay: 0.1 }}
            className="hp-w-100"
          >
            {headerChildren()}
          </motion.div>
        </Col>
      </Row>
    </Header>
  );
};