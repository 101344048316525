import React from 'react'

import { Col, Row } from 'antd'

export default function Footer() {
    return (
        <Col className="hp-mt-sm-48 hp-mt-96">
            <p className="hp-p1-body hp-text-center hp-text-color-black-60 hp-mb-8">Copyright 2024 @TMTCODE.</p>
        </Col>
    )
}
